import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/defidex/Banner';
import FaqSection from '../components/defidex/FaqSection';
import TechStack from '../components/defidex/TechStack';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import WeProvide from '../components/defidex/WeProvide';
import DevProcess from '../components/defidex/DevProcess';
import WhyChoose from '../components/defidex/WhyChoose';
import Whatis from '../components/defidex/Whatis';
import WeCreate from '../components/defidex/WeCreate';
import TypesOf from '../components/defidex/TypesOf';

const IcoConsult = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/defi-dex-aggregator-development/" />
        <title>DeFi DEX Aggregator Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is a well-known DeFi DEX Aggregator development company that provides the best DeFi Aggregator platform to drive your DeFi business to success" />
        <meta name="keywords" content="DeFi DEX Aggregator Development Company, DeFi DEX Aggregator Development Services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DeFi DEX Aggregator Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is a well-known DeFi DEX Aggregator development company that provides the best DeFi Aggregator platform to drive your DeFi business to success" />
        <meta property="og:url" content="https://www.coinsclone.com/defi-dex-aggregator-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/defidex/defi-dex-aggregator.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a well-known DeFi DEX Aggregator development company that provides the best DeFi Aggregator platform to drive your DeFi business to success" />
        <meta name="twitter:title" content="DeFi DEX Aggregator Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/defidex/defi-dex-aggregator.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | DeFi DEX Aggregator Development Company</span>
        </div>
      </div>
      <Whatis />
      <DevProcess />
      <TypesOf />
      <WeProvide />
      <WeCreate />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly /> 
    </Layout>
  </section>
)

export default IcoConsult
