import React from 'react'


const TypesOf = () => {

  return (
    <section className="typeof defidex pt-100 mb-0">
      <div className='gray-bg'>
        <div className="container">
          <div className="row">
              <h2 className="heading-h2 text-center"><span className="bluecolor">Types of</span> DEX Aggregators You Can Build for Business
              </h2>
          </div>
          <div className='row popular we-offer'>
              <div className='col-md-12 col-lg-3'>
                  <h3>Standard DEX Aggregator</h3>
                  <p className='pharagraph'>Integrates liquidity from multiple decentralized exchanges, offering users enhanced trading efficiency, competitive pricing, and a unified trading experience.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Cross-chain DEX Aggregator</h3>
                  <p className='pharagraph'>Allows seamless swapping of assets between diverse cryptocurrencies. It provides your users flexibility, interoperability, and access to a wider range of trading options.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Layer 2 DEX Aggregator</h3>
                  <p className='pharagraph'>Optimizes transaction speed and enhances your user experience with faster trades, lower fees, and improved scalability while maintaining security and decentralization.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Intent-based DEX Aggregator</h3>
                  <p className='pharagraph'>Offers personalized liquidity sourcing and optimized trade execution. It minimizes slippage, maximizes efficiency, and tailors trading strategies to individual needs.</p>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TypesOf